@font-face {
  font-family: 'Canela';
  src: url('~/assets/fonts/canela-webfonts/Canela-Thin-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Canela';
  src: url('~/assets/fonts/canela-webfonts/Canela-Regular-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Canela';
  src: url('~/assets/fonts/canela-webfonts/Canela-Medium-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Canela';
  src: url('~/assets/fonts/canela-webfonts/Canela-Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Canela';
  src: url('~/assets/fonts/canela-webfonts/Canela-Black-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Display';
  src: url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Lt.woff') format('woff'),
    url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Lt.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Display';
  src: url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Regular.woff') format('woff'),
    url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Display';
  src: url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Md.woff') format('woff'),
    url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Md.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Display';
  src: url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Bd.woff') format('woff'),
    url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-Bd.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Display';
  src: url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-XBd.woff') format('woff'),
    url('~/assets/fonts/helvetica-webfonts/HelveticaNowDisplay-XBd.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
