// Ionic Variables and Theming. For more info,  please see:
// http://ionicframework.com/docs/theming/

:root {
  // color primary
  --ion-color-primary: #2f2c26;
  --ion-color-primary-rgb: 47, 44, 38;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #292721;
  --ion-color-primary-tint: #44413c;
  // color-secondary
  --ion-color-secondary: #f3eee1;
  --ion-color-secondary-rgb: 243, 238, 225;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d6d1c6;
  --ion-color-secondary-tint: #f4f0e4;
  // color-tertiary
  --ion-color-tertiary: #757575;
  --ion-color-tertiary-rgb: 117, 117, 117;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #676767;
  --ion-color-tertiary-tint: #838383;
  // color-success
  --ion-color-success: #9bb474;
  --ion-color-success-rgb: 155, 180, 116;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #889e66;
  --ion-color-success-tint: #a5bc82;
  --ion-color-success-background: #dce5cf;
  // color-warning
  --ion-color-warning: #f9d483;
  --ion-color-warning-rgb: 249, 212, 131;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dbbb73;
  --ion-color-warning-tint: #fad88f;
  // color-danger
  --ion-color-danger: #ff6348;
  --ion-color-danger-rgb: 255, 99, 72;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e0573f;
  --ion-color-danger-tint: #ff735a;
  // color-dark
  --ion-color-dark: #000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000;
  --ion-color-dark-tint: #1a1a1a;
  // color-medium
  --ion-color-medium: #616161;
  --ion-color-medium-rgb: 97, 97, 97;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #555;
  --ion-color-medium-tint: #717171;
  // color-light
  --ion-color-light: #f8f6f1;
  --ion-color-light-rgb: 248, 246, 241;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dad8d4;
  --ion-color-light-tint: #f9f7f2;
  --ion-color-light-grey: #e4e2dd;
  // color-white
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #fff;
  // color-yellow
  --ion-color-yellow: #f8c782;
  --ion-color-yellow-shade: #f9d483;
  --ion-color-yellow-tint: #fdf0d4;
  // color-orange
  --ion-color-orange: #f4a22e;
  --ion-color-orange-rgb: 244, 162, 46;
  --ion-color-orange-contrast: #000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #d78f28;
  --ion-color-orange-tint: #f5ab43;
  // color-red
  --ion-color-red: #ee3c15;
  --ion-color-red-rgb: 238, 60, 21;
  --ion-color-red-contrast: #fff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #d13512;
  --ion-color-red-tint: #f0502c;
  // info colors
  --ion-color-blue: #a3d0da;
  --ion-color-warning: #fdf0d4;
  --ion-color-error: #fce2dc;
  // estimated chart
  --ion-color-chart-bad: #f4a22e;
  --ion-color-chart-good: #a3d0da;
  --ion-color-chart-expected: #98b172;

  --ion-color-new-grey: #6f6b63;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast); // color primary
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

// To enable dark mode,  replace line bellow with: '@media (prefers-color-scheme: dark) {'  // color-secondary
@media (prefers-color-scheme: darkDisabled) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    // color-primary
    --ion-color-primary: #fff;
    --ion-color-primary-rgb: 255, 255, 255;
    --ion-color-primary-contrast: #000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e0e0e0;
    --ion-color-primary-tint: #fff;
    // color-secondary
    --ion-color-secondary: #313131;
    --ion-color-secondary-rgb: 49, 49, 49;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #2b2b2b;
    --ion-color-secondary-tint: #464646;
    // color-tertiary
    --ion-color-tertiary: #f7f7f7;
    --ion-color-tertiary-rgb: 247, 247, 247;
    --ion-color-tertiary-contrast: #000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d9d9d9;
    --ion-color-tertiary-tint: #f8f8f8;
    // color-dark
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    // color-medium
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    // color-light
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #fff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    // color-white
    --ion-color-white: #222428;
    --ion-color-white-rgb: 34, 36, 40;
    --ion-color-white-contrast: #fff;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-shade: #1e2023;
    --ion-color-white-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: var(--ion-color-light);
    --ion-text-color: #fff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #ccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-toolbar-background: #0d0d0d;
    --ion-item-background: #000;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: var(--ion-color-light);
    --ion-text-color: #fff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
  }
}


