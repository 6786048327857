app-home-impact-campaign,
app-home-impact-campaign-update {
  ion-toolbar {
    background-color: var(--ion-color-primary);
  }

  ion-back-button {
    --color: var(--ion-color-light);
  }

  ion-content {
    --background: var(--ion-color-primary);
  }

  .swiper-slide {
    flex-direction: column;
    color: var(--ion-color-new-grey);

    img {
      width: 100% !important;
      max-width: auto !important;
      margin-bottom: 10px;
    }

    p {
      color: inherit;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-right: 30px;
      text-align: left;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    bottom: 0;
    height: 6px;
    width: 100%;

    .swiper-scrollbar-drag {
      background-color: var(--ion-color-success);
      border-radius: 10px;
      height: inherit;
    }
  }

  ion-skeleton-text {
    --background: rgba(255, 255, 255, 0.065);
    --background-rgb: 255, 255, 255;
    height: 20px;
    margin-bottom: 12px;

    &.image-holder {
      height: 200px;
    }

    &.date {
      height: 10px;
      width: 100px;
    }
  }

  h1 {
    font-family: var(--font-secondary);
    color: var(--ion-color-light);
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: -0.005em;
    margin-top: 0;
    br,
    span {
      display: none;
    }
  }

  h2 {
    color: var(--ion-color-light);
    font-family: var(--font-secondary);
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
  }

  .title-holder span {
    color: var(--ion-color-primary);
  }

  span,
  p {
    color: var(--ion-color-light);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .green {
    color: var(--ion-color-success);
  }

  .tag {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--ion-color-primary);
    font-family: var(--font-secondary);
    transform: rotate(-6deg);
    background: var(--ion-color-success);
    padding: 10px;
    display: inline-block;
    position: relative;
    z-index: 2;

    &.small {
      font-size: 21px;
    }

    p {
      font-size: inherit;
      color: inherit;
      margin: 0;
    }
  }

  .hero-holder {
    margin-bottom: 12px;
    position: relative;
    height: 860px;

    &.expired {
      height: 760px;
    }

    .hero-bg {
      position: absolute;
      background: url(/assets/images/publicis-campaing.png) 0 0 transparent no-repeat;
      top: 0;
      left: -15px;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 100vw;
      background-size: contain;
    }

    .hero-content {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .video {
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 18px;
      }

      img {
        width: 64px;
        height: 64px;
      }
    }

    .hero-btns {
      margin-top: 32px;
      margin-bottom: 60px;

      button {
        margin: 6px 0;
      }
    }

    .campaign-explanation {
      margin-top: auto;
      background: var(--ion-color-success);
      color: var(--ion-color-primary);
      padding: 32px;
      border-radius: 4px;
    }
  }

  .campaign-numbers {
    display: flex;
    align-items: flex-end;
    margin-top: 44px;
    color: var(--ion-color-success);

    h2 {
      font-size: 60px;
      line-height: 60px;
      letter-spacing: -0.015em;
      margin: 0;
      margin-right: 10px;
      color: var(--ion-color-success);
    }

    span {
      color: inherit;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.005em;
      margin-bottom: 4px;
    }
  }

  .date {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #f8f6f1;
  }

  .chart-holder {
    display: flex;
    position: relative;
    margin: 96px 0;
  }

  .chart-information {
    display: flex;
    flex-direction: column;
    color: var(--ion-color-success);
    width: 60%;

    h2 {
      color: inherit;
      font-weight: 500;
      font-size: 60px;
      line-height: 48px;
      letter-spacing: -0.015em;
      border-bottom: 2px solid var(--ion-color-success);
      padding-bottom: 7px;
      margin: 12px 0 9px;
    }

    span,
    p {
      color: inherit;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.005em;
      &:first-child {
        margin-top: 12px;
      }

      @media screen and (max-width: 375px) {
        margin-right: 36px;
      }

      a {
        border: none;
        color: inherit;
        text-decoration: underline;
        display: block;
      }
    }

    &.chart-white {
      color: var(--ion-color-light);
      h2 {
        border-color: var(--ion-color-light);
      }
    }
  }

  .chart-bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 162px;

    img {
      margin: 0;
    }
  }

  .campaign-logos {
    margin: 52px 0 98px 0;
  }

  .faq-questions {
    margin-top: 40px;
    margin-bottom: 100px;
  }

  .faq-question {
    &.active {
      .faq-question-header {
        color: var(--ion-color-success);
        border-color: var(--ion-color-success);
        margin-bottom: 0;

        ion-icon {
          color: inherit;
        }
      }
      .faq-question-header + .faq-question-content {
        display: block;
        padding: 58px 0;
        color: var(--ion-color-success);

        p,
        span,
        a {
          color: inherit;
        }
      }
    }
  }

  .faq-question-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: var(--ion-color-light);
    border-bottom: 1px solid var(--ion-color-light);
    padding: 0 0 9px 0;
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;

    ion-icon {
      margin-left: 5px;
    }
  }

  .faq-question-content {
    color: var(--ion-color-success);
    display: none;

    ion-icon {
      font-size: 10px;
    }

    h2,
    p {
      color: inherit;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .support-button--supported {
    font-weight: normal;
    position: relative;
  }

  ion-icon[name='heart'] {
    font-size: 26px;
    margin-bottom: 4px;
    margin-right: 40px;
    position: absolute;
    left: 8px;
    top: 9px;
  }
}

.carousel {
  margin-top: 18px;
  margin-bottom: 88px;

  p {
    margin-top: 16px;
    color: var(--ion-color-new-grey);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.logos {
  margin-top: 50px;
  margin-bottom: 80px;

  > div {
    margin-bottom: 36px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
  }

  .last-one {
    max-width: 70%;
  }
}
