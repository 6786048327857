/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import 'swiper/scss';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './styles/ionic-customization';
@import './styles/buttons';
@import './styles/fonts';
@import './styles/campaign';
@import './styles/notifications';

// design system
@import '../../../libs/ui/src/design-system/styles/ds';

:root {
  /* Set the background of the entire app */
  --ion-background-color: var(--ion-color-light);

  // Set font variable
  --font-primary: 'Helvetica Display';
  --font-secondary: 'Canela';

  // Set main ionic font to Inyova's primary
  --ion-font-family: var(--font-primary);
}

.ion-input__errors {
  text-align: left;
  font-size: 12px;
  height: 12px;

  span {
    color: var(--ion-color-danger);
    line-height: 1;
  }
}

html,
body {
  font-family: var(--font-primary);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
div,
ion-label {
  color: var(--ion-color-primary);
}

app-home-performance,
app-account-personal-details,
app-strategy,
app-account,
app-home {
  background-color: var(--ion-color-light);
}

app-offline,
app-force-update,
app-maintenance {
  background-color: var(--ion-color-secondary);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: url('./assets/images/hero-mountains.png');
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
  }
}

app-maintenance {
  &::before {
    background-size: auto 35%;
    background-image: url('./assets/images/tools.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    opacity: 1;

    @media only screen and (min-width: 380px) {
      background-size: auto 45%;
    }
  }
}

.signout-actionsheet {
  .action-sheet-title {
    &.sc-ion-action-sheet-md {
      height: auto;
    }
  }
}

.container {
  text-align: center;
}

.container--center {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

p {
  font-size: 16px;
  margin: 0;

  &.bold {
    font-weight: bold;
  }

  a {
    //link in paragraph
    border-bottom: 1px solid #2f2c26;
    color: #2f2c26;
    padding-bottom: 1px;
    text-decoration: none;
  }
}

.c--grey {
  color: #7f8085;
}

.align--center {
  text-align: center;
}

.align--left {
  text-align: left;
}

.float--right {
  float: right;
}

.active-reward__item.neon {
  div {
    display: flex;
    align-items: center;
    border-radius: 4px 0 0 4px;
    padding: 8px;
    background: linear-gradient(270deg, #ff3282 0.14%, #ff001e 100.14%);

    img {
      height: 40px;
      width: 40px;
      margin: 0;
      vertical-align: middle;
    }
  }

  p {
    padding: 4px 0;
    margin: auto 16px;
  }
}

.company__desc,
.company__extra-desc {
  font-size: 14px;

  p {
    font-size: 14px;
  }
}

.pillar3a-desc {
  p {
    margin: 12px 0;
  }
}

.ds-info-row {
  .extra-info {
    p {
      > p {
        font-size: inherit;
        color: inherit;
      }
    }
  }
}
