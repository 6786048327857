ul.ds-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);

  li {
    list-style: none;
    display: flex;
    gap: var(--spacing-3);
  }
}
