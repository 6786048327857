.ds {
  &-title-1 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-8);
    line-height: var(--flh-8);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-1);
  }

  &-title-2 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-7);
    line-height: var(--flh-7);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-1);
  }

  &-title-3 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-6);
    line-height: var(--flh-6);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-1);
  }

  &-heading-1 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-5);
    line-height: var(--flh-5);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-2);
  }

  &-heading-2 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-4);
    line-height: var(--flh-4);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-3);
  }

  &-heading-3 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-3);
    line-height: var(--flh-4);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-4);
  }

  &-ui-1 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-4);
    line-height: var(--flh-4);
    font-weight: var(--fw-500);
    letter-spacing: var(--fls-3);
  }

  &-ui-2 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-3);
    line-height: var(--flh-4);
    font-weight: var(--fw-500);
    letter-spacing: var(--fls-3);
  }

  &-ui-3 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-2);
    line-height: var(--flh-3);
    font-weight: var(--fw-500);
    letter-spacing: var(--fls-5);
  }

  &-running-1 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-4);
    line-height: var(--flh-5);
    font-weight: var(--fw-400);
    letter-spacing: var(--fls-3);
  }

  &-running-2 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-3);
    line-height: var(--flh-4);
    font-weight: var(--fw-400);
    letter-spacing: var(--fls-4);
  }

  &-running-3 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-2);
    line-height: var(--flh-3);
    font-weight: var(--fw-400);
    letter-spacing: var(--fls-6);
  }

  &-caption-1 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-3);
    line-height: var(--flh-3);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-4);
  }

  &-caption-2 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-2);
    line-height: var(--flh-3);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-5);
  }

  &-caption-3 {
    font-family: var(--font-primary) sans-serif;
    font-size: var(--fs-1);
    line-height: var(--flh-1);
    font-weight: var(--fw-700);
    letter-spacing: var(--fls-6);
  }
}
