@import '../structure/ds-typography';

.ds-form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-4);

  &.has-error {
    .ds-label {
      color: var(--color-system-critical);
    }
    .ds-input {
      box-shadow: var(--shadow-input-border-error);
      margin-bottom: var(--spacing-1);
    }
  }

  &.has-helper {
    .ds-input {
      margin-bottom: var(--spacing-1);
    }
  }
}

.ds-label {
  @extend .ds-ui-2;
  color: var(--color-core-neutral-7);
  margin-bottom: var(--spacing-1);
}

.ds-input-helper {
  @extend .ds-ui-3;
  color: var(--color-core-neutral-7);
}

.ds-input-error {
  @extend .ds-ui-3;
  color: var(--color-system-critical);
}

// INPUT field
.ds-input {
  all: unset;
  background-color: var(--color-core-neutral-0);
  color: var(--color-core-neutral-10);
  line-height: var(--flh-4);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-input-border);
  padding: var(--spacing-3);
  @extend .ds-ui-1;

  &::placeholder {
    color: var(--color-core-neutral-5);
  }

  &:focus-visible {
    box-shadow: var(--shadow-input-border-selected);
    outline-offset: var(--border-1);
    outline: var(--border-3) solid var(--color-core-brand-2-4);
  }

  &.disabled,
  &:disabled {
    background-color: var(--color-core-neutral-2);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
