.ds-rows-white {
  box-shadow: var(--shadow-elavated);
  margin-bottom: var(--spacing-8);
  border-radius: var(--radius-2);

  .ds-row-white {
    display: flex;
    flex-direction: column;
    background-color: var(--color-core-neutral-0);
    margin-bottom: calc(var(--spacing-1) / 2);
    padding: var(--spacing-3) var(--spacing-4);

    &.only-one-row {
      border-radius: inherit !important;
    }

    &.extra-padding {
      padding-top: var(--spacing-4);
      padding-bottom: var(--spacing-4);
    }

    &:first-of-type {
      border-radius: var(--radius-2) var(--radius-2) var(--radius-0) var(--radius-0);
    }

    &:last-of-type {
      border-radius: var(--radius-0) var(--radius-0) var(--radius-2) var(--radius-2);
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      gap: var(--spacing-1);

      > div {
        display: flex;
        gap: var(--spacing-1);
        align-items: center;

        .ds-ui-2 {
          color: var(--color-core-neutral-7);
        }

        &:first-child {
          flex-direction: column;
          align-items: flex-start;
        }

        &:last-child {
          .ds-ui-2 {
            color: var(--color-core-neutral-10);
          }
        }
      }

      ion-icon {
        font-size: var(--icon-size-1);
        color: var(--color-core-neutral-5);

        &[name='copy'] {
          font-size: var(--icon-size-3);
        }
      }
    }

    > .info {
      margin-top: var(--spacing-2);

      .ds-ui-3 {
        color: var(--color-core-neutral-7);
      }
    }
  }
}
