.ds-color-core-neutral-1 {
  color: var(--color-core-neutral-1);
}

.ds-color-core-neutral-2 {
  color: var(--color-core-neutral-2);
}

.ds-color-core-neutral-3 {
  color: var(--color-core-neutral-3);
}

.ds-color-core-neutral-4 {
  color: var(--color-core-neutral-4);
}

.ds-color-core-neutral-5 {
  color: var(--color-core-neutral-5);
}

.ds-color-core-neutral-6 {
  color: var(--color-core-neutral-6);
}

.ds-color-core-neutral-7 {
  color: var(--color-core-neutral-7);
}

.ds-color-core-neutral-8 {
  color: var(--color-core-neutral-8);
}

.ds-color-core-neutral-9 {
  color: var(--color-core-neutral-9);
}

.ds-color-core-neutral-10 {
  color: var(--color-core-neutral-10);
}

.ds-color-error {
  color: var(--color-system-critical);
}
