.notification-item {
  margin: 15px 15px;
  position: relative;

  &__content {
    padding: 24px;

    > div {
      max-height: 60px;
      overflow: hidden;
    }

    &.with-image {
      width: calc(100% - 120px);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  &__wrapper {
    display: flex;
    position: relative;
    align-items: center;
    background: var(--ion-color-white);
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    box-shadow: 0px 1px 0px rgba(47, 44, 38, 0.25);
  }

  .first-stack,
  .second-stack {
    position: relative;
    top: 1px;
    border-radius: 4px;
    width: calc(100% - 10px);
    margin: 0 auto;
    height: 4px;
    background: #f8f6f1;
    box-shadow: 0px 1px 0px rgba(47, 44, 38, 0.25);
    z-index: 2;
  }

  .second-stack {
    width: calc(100% - 20px);
    z-index: 1;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 16px;
    stroke: none;
    cursor: pointer;
  }
}

.banner-wrapper {
  margin: 24px 0 48px;
  display: flex;
  position: relative;
  align-items: center;
  background: var(--ion-color-white);
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  min-height: 136px;

  &__content {
    padding: 24px;
    margin-right: 115px;

    h2 {
      font-family: var(--font-primary);
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__image {
    position: absolute;
    width: 109px;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
