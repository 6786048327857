.button-fab {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    background-color: var(--ion-color-primary);
    border-radius: 100%;
    color: var(--ion-color-primary-contrast);
    display: block;
    height: 58px;
    outline: none;
    padding: 9px;
    position: relative;
    width: 58px;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.2);

    ion-icon {
      font-size: 30px;
    }
  }

  ion-label {
    color: var(--ion-color-primary);
    display: block;
    margin: 5px 0;
    font-size: 12px;
    line-height: 1;
  }

  &--small {
    button {
      ion-icon {
        font-size: 30px;
      }

      ion-label {
        font-size: 10px;
      }
    }
  }

  &--white {
    button {
      background-color: var(--ion-color-white);
    }
  }

  &.align-right {
    margin-left: auto;
  }
}

button.outline {
  display: flex;
  justify-content: center;
  width: 100%;
  background: transparent;
  border: 2px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  border-radius: 4px;
  padding: 12px;
  margin: 8px 0;
  font-weight: 700;
  line-height: 14px;
  font-size: 14px;

  &--white {
    border-color: var(--ion-color-light);
    color: var(--ion-color-light);
  }
}

ion-button {
  &.outline {
    --background: var(--ion-color-contrast-primary);
    color: var(--ion-color-primary);
    border: 2px solid var(--ion-color-primary);
    font-weight: bold;
    width: 100%;
  }
}

.support-button {
  border-radius: 4px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-primary);
  width: 100%;
  padding: 12px;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  margin: 44px 0;

  &--green {
    border: 2px solid var(--ion-color-success);
    color: var(--ion-color-success);
    background: transparent;
  }

  &--supported {
    background-color: var(--ion-color-success);
    color: var(--ion-color-primary);
  }
}

button.primary, button.secondary {
  width: 100%;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

button.primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);
}

button.secondary {
  background-color: transparent;
  color: var(--ion-color-primary);
}
