.ds-card {
  display: flex;
  background: var(--color-core-neutral-0);
  box-shadow: var(--shadow-elavated);
  border-radius: var(--radius-2);
  padding: var(--spacing-4);
  margin-bottom: var(--spacing-4);

  &.small {
    padding: var(--spacing-3);
    gap: var(--spacing-2);

    .ds-caption-1 {
      margin: var(--spacing-1) 0;
    }
  }
}

.ds-card-image {
  width: 64px;
  height: 64px;

  img {
    border-radius: var(--radius-1);
    object-fit: cover;
    height: 100%;
  }
}
