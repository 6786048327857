:root {
  // Font family
  --font-primary: 'Helvetica Display';
  --font-secondary: 'Canela';

  // Font sizes
  --fs-1: 10px;
  --fs-2: 12px;
  --fs-3: 14px;
  --fs-4: 16px;
  --fs-5: 20px;
  --fs-6: 28px;
  --fs-7: 32px;
  --fs-8: 36px;

  // Font line-height
  --flh-1: 12px;
  --flh-2: 14px;
  --flh-3: 16px;
  --flh-4: 20px;
  --flh-5: 24px;
  --flh-6: 32px;
  --flh-7: 36px;
  --flh-8: 40px;

  // Font letter-spacing
  --fls-1: 0;
  --fls-2: 0.09px;
  --fls-3: 0.19px;
  --fls-4: 0.26px;
  --fls-5: 0.33px;
  --fls-6: 0.47px;

  // Font weights
  --fw-100: 100;
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --fw-950: 950;

  // Spacing
  --spacing-0: 0;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-13: 52px;
  --spacing-14: 56px;
  --spacing-15: 60px;
  --spacing-16: 64px;
  --spacing-17: 68px;
  --spacing-18: 72px;
  --spacing-19: 76px;
  --spacing-20: 80px;
  --spacing-21: 84px;
  --spacing-22: 88px;
  --spacing-23: 92px;
  --spacing-24: 96px;
  --spacing-25: 100px;
  // TODO: Add the rest if there is a need

  // Border radius
  --radius-0: 0;
  --radius-1: 4px;
  --radius-2: 8px;
  --radius-3: 12px;
  --radius-4: 16px;
  --radius-5: 20px;
  --radius-6: 24px;
  --radius-7: 28px;
  --radius-8: 32px;

  // Border width
  --border-0: 0;
  --border-1: 1px;
  --border-2: 2px;
  --border-3: 3px;
  --border-4: 4px;
  --border-5: 5px;
  --border-6: 6px;

  // Opacity
  --opacity-0: 0;
  --opacity-1: 0.25;
  --opacity-2: 0.5;
  --opacity-2: 0.8;

  // Colors
  // Primary Brand colors
  --color-core-brand-1-1: #fdebe7;
  --color-core-brand-1-2: #fac8bd;
  --color-core-brand-1-3: #f58d75;
  --color-core-brand-1-4: #f16647;
  --color-core-brand-1-5: #ee3c15;
  --color-core-brand-1-6: #c32f0e;
  --color-core-brand-1-7: #93230b;
  --color-core-brand-1-8: #641807;
  --color-core-brand-1: var(--color-core-brand-1-5);

  --color-core-brand-2-1: #fdf3dd;
  --color-core-brand-2-2: #fbe6b6;
  --color-core-brand-2-3: #f9d483;
  --color-core-brand-2-4: #f7c455;
  --color-core-brand-2-5: #f4b324;
  --color-core-brand-2-6: #db9a0b;
  --color-core-brand-2-7: #aa7708;
  --color-core-brand-2-8: #704f05;
  --color-primary-brand-2: var(--color-core-brand-2-3);

  --color-core-brand-3-1: #f9f6f0;
  --color-core-brand-3-2: #f3eee1;
  --color-core-brand-3-3: #ebe2cc;
  --color-core-brand-3-4: #e2d6b6;
  --color-core-brand-3-5: #dccda8;
  --color-core-brand-3-6: #d3c192;
  --color-core-brand-3-7: #cdb883;
  --color-core-brand-3-8: #c5ac6d;
  --color-primary-brand-3: var(--color-core-brand-1-2);

  // Secondary Brand colors
  --color-core-brand-4-1: #ecf1e4;
  --color-core-brand-4-2: #dee6d1;
  --color-core-brand-4-3: #cbd8b6;
  --color-core-brand-4-4: #b3c695;
  --color-core-brand-4-5: #9bb474;
  --color-core-brand-4-6: #829f56;
  --color-core-brand-4-7: #677e44;
  --color-core-brand-4-8: #4c5d32;
  --color-core-brand-4: var(--color-core-brand-4-5);

  --color-core-brand-5-1: #fdeed8;
  --color-core-brand-5-2: #fbe1bb;
  --color-core-brand-5-3: #f9d094;
  --color-core-brand-5-4: #f7b85f;
  --color-core-brand-5-5: #f4a22e;
  --color-core-brand-5-6: #e48a0c;
  --color-core-brand-5-7: #b36d09;
  --color-core-brand-5-8: #834f07;
  --color-core-brand-5: var(--color-core-brand-5-5);

  --color-core-brand-6-1: #e2f0f3;
  --color-core-brand-6-2: #c8e3e9;
  --color-core-brand-6-3: #a3d0da;
  --color-core-brand-6-4: #80becc;
  --color-core-brand-6-5: #5babbd;
  --color-core-brand-6-6: #4292a4;
  --color-core-brand-6-7: #33717f;
  --color-core-brand-6-8: #24515c;
  --color-core-brand-6: var(--color-core-brand-6-3);

  // Neutral
  --color-core-neutral-0: #ffffff;
  --color-core-neutral-1: #f8f8f7;
  --color-core-neutral-2: #edebe8;
  --color-core-neutral-3: #e1dfda;
  --color-core-neutral-4: #cbc7be;
  --color-core-neutral-5: #b4aea2;
  --color-core-neutral-6: #9d9586;
  --color-core-neutral-7: #857c6b;
  --color-core-neutral-8: #686154;
  --color-core-neutral-9: #4d483d;
  --color-core-neutral-10: #2f2c26;

  // System colors
  --color-system-info-1: #d0e5f1;
  --color-system-info-2: #85bddc;
  --color-system-info-3: #3280ae;
  --color-system-info: var(--color-system-info-2);

  --color-system-success-1: #d7edc9;
  --color-system-success-2: #88ca5f;
  --color-system-success-3: #579532;
  --color-system-success: var(--color-system-success-3);

  --color-system-warning-1: #ffdab8;
  --color-system-warning-2: #ffa048;
  --color-system-warning-3: #f57600;
  --color-system-warning: var(--color-system-warning-2);

  --color-system-critical-1: #ffc2b8;
  --color-system-critical-2: #ff6348;
  --color-system-critical-3: #e02100;
  --color-system-critical: var(--color-system-critical-2);

  // Shadows
  --shadow-subsurface: inset 0px 0px 8px rgba(47, 44, 38, 0.08), inset 0px 0px 8px rgba(47, 44, 38, 0.08);
  --shadow-base: transparent;
  --shadow-flat: transparent;
  --shadow-elavated: 0px 2px 4px rgba(47, 44, 38, 0.04), 0px 0px 8px rgba(47, 44, 38, 0.08);
  --shadow-raised: 0px 2px 16px rgba(47, 44, 38, 0.2);
  --shadow-floating: 0px 2px 8px rgba(47, 44, 38, 0.12), 0px 16px 32px rgba(47, 44, 38, 0.16);
  --shadow-input-focus: 0px 0px 0px 3px var(--color-core-brand-2-4);
  --shadow-input-border: 0 0 0 var(--border-1) var(--color-core-neutral-4);
  --shadow-input-border-selected: 0 0 0 var(--border-1) var(--color-core-neutral-10);
  --shadow-input-border-error: 0 0 0 var(--border-1) var(--color-system-critical-3);
  --shadow-input-border-disabled: 0 0 0 var(--border-1) var(--color-core-neutral-2);

  //Icons
  --icon-size-0: 12px;
  --icon-size-1: 16px;
  --icon-size-2: 20px;
  --icon-size-3: 24px;
  --icon-size-4: 32px;
  --icon-size-5: 36px;

  // Eases
  --ease-1: cubic-bezier(0.45, 0, 0.4, 1);
  --ease-2: cubic-bezier(0.83, 0, 0.17, 1);

  // Durations
  --duration-quick: 150ms;
  --duration-moderate: 250ms;
  --duration-extended: 350ms;
  --duration-slow: 500ms;
  --duration-extra-slow: 1000ms;
}
